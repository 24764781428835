<template>
  <div>
    <p class="text-xl text-bold">List Item Units</p>
    <hr />
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="data"
      :max-items="data.length"
      :total="total"
      @search="handleSearch"
      @change-page="handleChangePage"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.start }} - {{ this.end }} of {{ this.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="unit">Unit</vs-th>
        <vs-th sort-key="action">Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data.records">
          <vs-td :data="data.records[indextr].code">{{
            data.records[indextr].code
          }}</vs-td>
          <vs-td :data="data.records[indextr].name">{{
            data.records[indextr].name
          }}</vs-td>
          <vs-td :data="data.records[indextr].unit">{{
            data.records[indextr].unit
          }}</vs-td>
          <vs-td style="align-item-center">
            <vs-button
              icon="add"
              color="success"
              type="filled"
              size="small"
              @click="addItem(tr)"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination :total="totalPage" v-model="setPage" />
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import _ from "lodash";
const MasterRepository = RepositoryFactory.get("master");

export default {
  props: {
    isGetData: {
      type: Boolean,
      default: true,
    },
    itemUnitIDs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      data: [],
      total: 0,
      totalPage: 0,
      totalSearch: 0,
      limits: [5, 10, 25, 50, 100, "All"],
      start: 1,
      end: 0,
      params: {
        ids: null, // 1,2,3
        search: "",
        length: 10,
        page: 1,
        order: "created_at",
        sort: "asc",
      },
    };
  },
  mounted() {
    if (this.isGetData) {
      this.getData();
    }
  },
  methods: {
    async addItem(item) {
      this.$emit("data", item);
    },
    handleSearch: _.debounce(function (search) {
      this.params.search = search;
      this.params.page = 1;
      this.getData();
    }, 500),
    async setParams(params) {
      this.params = params;
    },
    async handleChangelength(val) {
      this.params.length = val == "All" ? this.params.total : val;
      this.params.page = 1;
      this.getData();
    },
    async getData() {
      this.$vs.loading();
      try {
        // join props to params
        this.params.ids = this.itemUnitIDs.join(",");

        const resp = await MasterRepository.getTableItemUnit(this.params);
        if (resp.code == 200) {
          this.total = resp.data.total_record;
          this.totalPage = resp.data.total_page;
          this.totalSearch = resp.data.total_record_search;
          this.params.length = resp.data.total_record_per_page;
          this.data = resp.data;
          await this.setStartEnd();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching item units",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    async handleChangePage(page) {
      this.params.page = page;
      await this.getData();
    },
    async setStartEnd() {
      let valStart =
        this.params.length * this.params.page - this.params.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      if (this.total == 0) {
        valStart = 0;
      }
      let valEnd = this.params.length * this.params.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.totalSearch;
      }

      this.start = valStart;
      this.end = valEnd;
    },
  },
};
</script>
